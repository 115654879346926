import { navigate } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import useGtmEvent from '../../../hooks/useGtmEvent'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './personas.module.scss'

interface PersonaItemProps {
  avatarBg: string
  title: string
  description: string
  thumbnail: any
  link?: Array<{
    url: string
    name: string
  }>
  residenceName?: string
}
export default function PersonaItem(props: PersonaItemProps) {
  const { avatarBg, title, description, thumbnail, link, residenceName } = props
  const { t } = useTranslation(['common'])

  const onClickPersona = () => {
    let params: any = {
      type: title,
      click_text: link && link[0] && link[0].name !== '-' && link[0].name !== '' ? link[0].name : t('Je découvre', 'common'),
    }
    if (residenceName) {
      params['residence'] = residenceName
    }
    useGtmEvent('cta_type_colifer', params)
    if (link && link[0] && link[0].url && link[0].url !== '-') {
      navigate(link[0].url)
    }
  }

  return (
    <div
      className={styles.testimony}
      style={{ '--avatar-bg': avatarBg } as React.CSSProperties}
      onClick={onClickPersona}
    >
      <div className={styles.testimony__avatar}>
        {thumbnail && thumbnail.gatsbyImageData ? (
          <GatsbyImage
            image={{
              ...thumbnail.gatsbyImageData,
              width: 77,
              height: 85,
            }}
            alt={title}
          />
        ) : (
          <StaticImage
            src="../../../images/avatar.png"
            alt={title}
            objectFit="cover"
            loading="eager"
          />
        )}
      </div>
      <div className={styles.testimony__content}>
        <Text
          tag="p"
          as="h6"
          className={`${styles.personasHeadTitle} c-bleu-fonce`}
          dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
        ></Text>
        <Text
          tag="p"
          as="bodyH3"
          className="c-grey"
          dangerouslySetInnerHTML={{ __html: replaceHife(description) }}
        ></Text>
        {link && link[0] && link[0].url && link[0].url !== '-' && (
          <KLink
            to={link[0].url}
            label={
              `${t(link[0].name, 'common')}` || `${t('Je découvre', 'common')}`
            }
          />
        )}
      </div>
    </div>
  )
}
