import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import Text from '../../Core/Text'
import * as styles from './blockSeoText.module.scss'
import replaceHifeAndAddLocaleEn from '../../../utils/replaceHifeAndAddLocaleEn'
import useCustomLocale from '../../../hooks/useCustomLocale'

interface BlockSeoTextProps {
  content: string
}

export default function BlockSeoText(props: BlockSeoTextProps) {
  const { content } = props

  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation('common')

  const seoTextContentRef = useRef<HTMLDivElement>(null)
  const seoTextContainerRef = useRef<HTMLDivElement>(null)
const currLocale = useCustomLocale()
  useEffect(() => {
    if (
      seoTextContentRef &&
      seoTextContentRef.current &&
      seoTextContainerRef &&
      seoTextContainerRef.current
    ) {
      if (
        seoTextContentRef.current.scrollHeight >
          seoTextContentRef.current.clientHeight + 10 ||
        seoTextContentRef.current.scrollWidth >
          seoTextContentRef.current.clientWidth
      ) {
        seoTextContainerRef.current.classList.add('is-overflow', 'is-hidden')
      }

      if (seoTextContainerRef.current.classList.contains('is-hidden')) {
        // seoTextContentRef.current.style.maxHeight = '3.3em'
        seoTextContentRef.current.style.maxHeight = '3.6em'
      } else {
        seoTextContentRef.current.style.maxHeight =
          seoTextContentRef.current.scrollHeight + 'px'
      }
    }
  }, [])

  const onToggleShowMore = () => {
    if (seoTextContainerRef.current && seoTextContentRef.current) {
      if (seoTextContainerRef.current.classList.contains('is-hidden')) {
        console.log(
          'Is Hidden, show more',
          seoTextContentRef.current.scrollHeight,
          seoTextContentRef.current.style.maxHeight
        )
        seoTextContentRef.current.style.maxHeight =
          seoTextContentRef.current.scrollHeight + 'px'
      } else {
        //seoTextContentRef.current.style.maxHeight = '3.3em'
        seoTextContentRef.current.style.maxHeight = '3.6em'
      }
      seoTextContainerRef.current.classList.toggle('is-hidden')

      setShowMore(!showMore)
    }
  }

  return (
    <div>
      <div
        ref={seoTextContainerRef}
        className={`container ${styles.serviceSeo}`}
      >
        <div ref={seoTextContentRef} className={`${styles.jsReadMore}`}>
          <Text
            tag="div"
            dangerouslySetInnerHTML={{ __html: replaceHifeAndAddLocaleEn(content,currLocale) }}
          />
        </div>
        {content.length > 120 && (
          <button
            className={`${styles.jsReadMoreToggle}`}
            onClick={onToggleShowMore}
          >
            {showMore ? t('Voir moins', 'common') : t('Voir plus', 'common')}
          </button>
        )}
      </div>
    </div>
  )
}
