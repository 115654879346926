import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './digital.module.scss'

interface DigitalProps {
  title: string
  illustration?: {
    src: string
  }
  link?: {
    url: string
    name: string
    target?: string
    type?: string
  }
  description: string
  type?: 'Discovery' | 'Experience'
  titleColor?: string
  bg?: string
}

export default function Digital(props: DigitalProps) {
  const {
    type = 'Experience',
    title,
    illustration,
    description,
    titleColor = '142336',
    bg = 'A3DBE2',
    link,
  } = props

  const { t } = useTranslation(['common'])

  const onClickPush = () => {
    navigate(link?.url as string)
  }

  return (
    <div onClick={onClickPush}>
      <div
        className={`${styles.cardIllus} ${styles[`card${type}`]}`}
        style={{ '--bg': `#${bg}` } as React.CSSProperties}
      >
        {illustration && illustration.src && (
          <div className={styles.cardIllus__img}>
            <img src={illustration.src} alt={title} />
          </div>
        )}
        <div
          className={`${styles.cardIllus__desc} ${
            !illustration ? 'pl-7 lg:pl-14' : ''
          }`}
        >
          <Text
            tag="p"
            as="h3"
            className={`${styles.pushTitle} digital-title`}
            style={{ color: `#${titleColor}` }}
          >
            {title}
          </Text>
          <Text
            tag="p"
            as="bodyH1"
            dangerouslySetInnerHTML={{ __html: replaceHife(description) }}
          ></Text>
          {link && (
            <KLink
              to={link.url}
              label={link.name}
              hover={`#${titleColor}`}
              btnType="dark"
              type={link.type}
            />
          )}
        </div>
      </div>
    </div>
  )
}
