// extracted by mini-css-extract-plugin
export var link = "partner-module--link--7100f";
export var partner = "partner-module--partner--6850e";
export var partnerWrap = "partner-module--partnerWrap--8bb56";
export var partner__inner = "partner-module--partner__inner--d396f";
export var partner__logo = "partner-module--partner__logo--79d71";
export var partner__logos = "partner-module--partner__logos--c2568";
export var partner__pg = "partner-module--partner__pg--02386";
export var partner__title = "partner-module--partner__title--98bdc";
export var partner__txt = "partner-module--partner__txt--209fd";
export var patternBg0 = "partner-module--pattern-bg-0--8d7e8";