import React from 'react'
import Summer from '../Summer'
import Digital from '../Digital'
import * as styles from './push.module.scss'

import illusDigital from '../../../images/svg/illus-digital.svg'
import { useInView } from 'react-intersection-observer'
import PushSimple from '../PushSimple'

interface PushProps {
  data: {
    itemsPush: Array<{
      title: string
      subtitle: string
      frameColor: string
      contentTypeAlias: string
      titleColor: string
      backgroundColor: string
      listItem?: string[]
      link?: Array<{
        url: string
        type: string
        name: string
      }>
      linkEvent: Array<string>
      illustration: {
        src: string
        gatsbyImageData: any
      }
      pushStyle?: string
    }>
  }
  position?: string
  className?: string
  inListing?: boolean
}

export default function Push(props: PushProps) {
  const { data, className = '', inListing, position } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  if (!data.itemsPush) return null

  return data.itemsPush.length < 2 &&
    data.itemsPush[0] &&
    data.itemsPush[0].pushStyle === 'Simple' ? (
    <PushSimple
      ref={ref}
      className={`single-push animate ${inView ? 'in-view' : ''}`}
      data={{
        contentTypeAlias: data.itemsPush[0].contentTypeAlias,
        title: data.itemsPush[0].title || 'THE SUMMER DEAL',
        paragraph: data.itemsPush[0].subtitle,
        listItem: data.itemsPush[0].listItem || [],
        link:
          data.itemsPush[0].link && data.itemsPush[0].link[0]
            ? data.itemsPush[0].link[0]
            : {
                name: 'Réservez maintenant',
                url: '/',
              },
        linkEvent:
          data.itemsPush[0].linkEvent && data.itemsPush[0].linkEvent[0]
            ? data.itemsPush[0].linkEvent[0]
            : undefined,
      }}
      titleColor={data.itemsPush[0].titleColor}
    />
  ) : data.itemsPush.length < 2 && data.itemsPush[0] ? (
    <Summer
      ref={ref}
      className={`single-push animate ${inView ? 'in-view' : ''}`}
      data={{
        contentTypeAlias: data.itemsPush[0].contentTypeAlias,
        title: data.itemsPush[0].title || 'THE SUMMER DEAL',
        paragraph: data.itemsPush[0].subtitle,
        listItem: data.itemsPush[0].listItem || [],
        link:
          data.itemsPush[0].link && data.itemsPush[0].link[0]
            ? data.itemsPush[0].link[0]
            : {
                name: 'Réservez maintenant',
                url: '/',
              },
        linkEvent:
          data.itemsPush[0].linkEvent && data.itemsPush[0].linkEvent[0]
            ? data.itemsPush[0].linkEvent[0]
            : undefined,
        position,
      }}
      titleColor={data.itemsPush[0].titleColor}
      bg={data.itemsPush[0].backgroundColor}
      illustration={
        data.itemsPush[0].illustration
          ? data.itemsPush[0].illustration
          : undefined
      }
    />
  ) : (
    <section
      ref={ref}
      className={`double-push ${styles.push} ${className} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div
        className={`${inListing ? '' : 'container'} lg:grid grid-cols-2 gap-4`}
      >
        {data.itemsPush.map((d, index) => {
          if (d.pushStyle === 'Simple') {
            return (
              <PushSimple
                ref={ref}
                className={`single-push animate ${inView ? 'in-view' : ''}`}
                data={{
                  contentTypeAlias: d.contentTypeAlias,
                  title: d.title || 'THE SUMMER DEAL',
                  paragraph: d.subtitle,
                  listItem: d.listItem || [],
                  link: d.link && d.link[0] && d.link[0],
                }}
                titleColor={d.titleColor}
              />
            )
          }
          return (
            <Digital
              illustration={d.illustration ? d.illustration : undefined}
              title={d.title}
              description={d.subtitle}
              key={`d-${index}`}
              titleColor={d.titleColor}
              bg={d.backgroundColor}
              link={d.link && d.link[0]}
            />
          )
        })}
      </div>
    </section>
  )
}
