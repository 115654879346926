import React, { AllHTMLAttributes, ReactNode, useState } from 'react'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import * as styles from './personas.module.scss'
import PersonaItem from './PersonaItem'
import replaceHife from '../../../utils/replaceHife'

interface PersonasProps extends Omit<AllHTMLAttributes<ReactNode>, 'data'> {
  data: {
    contentTypeAlias: string
    title: string
    description?: string
    personas: Array<{
      name: string
      subtitle: string
      title: string
      frameColor: string
      thumbnail: any
      link?: Array<{
        url: string
        name: string
      }>
    }>
  }
  residence?: {
    name: string
    shortName: string
  }
  tag?: keyof JSX.IntrinsicElements
}

export default function Personas(props: PersonasProps) {
  const { data, className = '', tag = 'h2', residence } = props
  const { title, description, personas = [] } = data

  const [slideChanged, setSlideChanged] = useState(false)

  const breakpoint = useBreakpoint()

  return (
    <section
      id={data.contentTypeAlias}
      className={`${styles.personas} ${className}`}
    >
      <div className={`container ${!slideChanged ? 'init-slide' : ''}`}>
        <div className={styles.personasHead}>
          <Text
            tag={tag}
            as="h3"
            className="h2"
            dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
          ></Text>
          {description && breakpoint !== 'sm' && (
            <Text
              tag="p"
              as="subtitleH2"
              dangerouslySetInnerHTML={{
                __html: replaceHife(description),
              }}
            ></Text>
          )}
        </div>
        <div
          className={
            ['sm', 'md'].includes(breakpoint) ? '' : 'grid grid-cols-3 gap-4'
          }
        >
          {['sm', 'md'].includes(breakpoint) ? (
            <Slider
              navigation={false}
              pagination={true}
              onSlideChange={() => setSlideChanged(true)}
              data={personas.map((d, index) => ({
                content: (
                  <PersonaItem
                    key={`c-${index}`}
                    avatarBg={`#${d.frameColor}`}
                    title={d.title}
                    description={d.subtitle}
                    thumbnail={d.thumbnail}
                    link={d.link}
                    residenceName={residence?.shortName || residence?.name}
                  />
                ),
              }))}
            />
          ) : (
            personas.map((d, index) => (
              <PersonaItem
                avatarBg={`#${d.frameColor}`}
                key={`c-${index}`}
                title={d.title}
                description={d.subtitle}
                thumbnail={d.thumbnail}
                link={d.link}
                residenceName={residence?.shortName || residence?.name}
              />
            ))
          )}
        </div>
      </div>
    </section>
  )
}
