import React from 'react'
import { navigate } from 'gatsby'
import { Link } from '../../../../plugins/gatsby-plugin-intl-kley'
import useLinkLocalized from '../../../hooks/useLinkLocalized'
import replaceHife from '../../../utils/replaceHife'
import Button from '../../Core/Button'
import Text from '../../Core/Text'
import ArrowCircle from '../../Icon/ArrowCircle'
import * as styles from './pushSimple.module.scss'
import useGtmEvent from '../../../hooks/useGtmEvent'

const PushSimple = (
  props: {
    data: {
      contentTypeAlias: string
      title: string
      paragraph: string
      link?: { name: string; url: string; type?: string; target?: string }
      linkEvent?: string
      listItem?: string[]
    }
    bg?: string
    titleColor?: string
    className?: string
    blockClassName?: string
  },
  ref: any
) => {
  const {
    data,
    bg = 'F7EBD7',
    titleColor = 'd85935',
    className = '',
    blockClassName = '',
  } = props
  const { title, paragraph, link, linkEvent, listItem } = data

  console.log(link, 'link')

  const linkIsModal = link?.url.match(/\/popins\//)

  const Tag = linkIsModal ? 'div' : Link

  const onTrackClickPush = () => {
    if (linkEvent) {
      useGtmEvent(linkEvent)
    }
  }

  return (
    <section
      ref={ref}
      id={data.contentTypeAlias}
      className={`push-simple ${className}`}
    >
      <div
          className={`
            ${styles.pushSimple} 
            block 
            ${blockClassName} 
          `}
      >
      {/* <Tag
        to={linkIsModal
            ? undefined : link?.type === 'EXTERNAL'
                ? link?.url : useLinkLocalized({url: link?.url || ''})}
        onClick={!link  && !linkIsModal ? onTrackClickPush : undefined}
        className={`${styles.pushSimple} block ${blockClassName} ${
          link?.url && !linkIsModal ? 'cursor-pointer' : ''
        }`}
      > */}
        <div className={`${styles.pushSimple__content} pushSimple-content`}>
          <Text
            tag="p"
            as="h3"
            className={`${styles.pushSimpleTitle} pushSimple-title`}
            style={{ color: `#${titleColor}` }}
            dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
          ></Text>
          {paragraph && (
            <Text
              as="bodyH1"
              className="c-bleu-fonce"
              dangerouslySetInnerHTML={{ __html: replaceHife(paragraph) }}
            ></Text>
          )}
          {listItem && listItem?.length > 0 && (
            <ul className="mt-12 mb-9">
              {listItem?.map((l, index) => (
                <li key={`sml-${index}`} className="flex gap-x-2.5 mb-2">
                  <span className={styles.bullet}>
                    <ArrowCircle />
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: replaceHife(l) }}
                  ></span>
                </li>
              ))}
            </ul>
          )}
          {link && (
            <Button
              tag="link"
              to={link.url}
              btnType="light"
              className="mr-8 mt-2"
              tracking={onTrackClickPush}
              {...link}
            >
              {link.name}
            </Button>
          )}
        </div>
        {/* </Tag> */}
      </div>
    </section>
  )
}

export default React.forwardRef(PushSimple)
